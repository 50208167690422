import { useOktaAuth } from '@okta/okta-react';
import { Outlet } from 'react-router-dom';

export function AuthChecker() {
  const { oktaAuth, authState } = useOktaAuth();
  if (!authState) {
    return <></>;
  }

  if (!authState.isAuthenticated) {
    oktaAuth.setOriginalUri(window.location.pathname + window.location.search);
    oktaAuth.signInWithRedirect();
    return <></>;
  }

  return <Outlet />;
}
