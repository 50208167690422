import { useState } from 'react';

import { useSystems } from '../../api/hooks/useSystemsApi';
import { useRRSystemContext } from '../../context/rrSystemContext';
import { toRRSystemName } from '../../types/systems/System';
import Icon from '../library/icons/Icon';
import { DropDown } from '../library/inputs/DropDown';

import { Logo } from './Logo';
import { SettingsMenu } from './settings/SettingsMenu';
import { UserMenu } from './users/UserMenu';

export interface HeaderProps {
  onOpenClick: () => void;
}

function Header({ onOpenClick }: HeaderProps) {
  const [isSettingsMenuOpen, toggleIsSettingsMenuOpen] = useState<boolean>(false);
  const [isUserMenuOpen, toggleIsUserMenuOpen] = useState<boolean>(false);
  const { rrSystemId, selectRRSystem } = useRRSystemContext();
  const { systems } = useSystems();
  const [settingsMenuAnchorElement, setSettingsMenuAnchorElement] = useState<null | HTMLElement>(
    null
  );
  const rrSystemsAsOptionItems = systems
    .map(system => {
      return {
        value: system._id,
        label: toRRSystemName(system),
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const handleSelectRRSystem = (rrSystemId: string) => {
    selectRRSystem(rrSystemId);
  };
  return (
    <nav className="uib-menubar">
      <div
        className="uib-menubar__main-menu"
        style={{ visibility: rrSystemId ? 'visible' : 'hidden' }}
      >
        <button
          type="button"
          id={'mainMenuButton'}
          className="uib-main-menu-button"
          onClick={onOpenClick}
        />
      </div>
      <div className="uib-menubar__logo">
        <Logo />
      </div>
      {systems.length > 1 && rrSystemId && (
        <div className="center-dropdown">
          <DropDown
            id="changeRRSystem"
            dropDownItems={rrSystemsAsOptionItems}
            value={rrSystemId}
            onChange={handleSelectRRSystem}
            style={{ height: '30px' }}
          />
        </div>
      )}
      <div>
        <button
          id={'appSettingBtn'}
          className={`uib-iconbutton uib-iconbutton--menu-bar settings-button ${
            isSettingsMenuOpen ? 'settings-button-active' : null
          }`}
          onClick={event => {
            setSettingsMenuAnchorElement(event?.currentTarget);
            toggleIsUserMenuOpen(false);
            toggleIsSettingsMenuOpen(!isSettingsMenuOpen);
          }}
        >
          <Icon iconName={'settings'} />
        </button>
        {isSettingsMenuOpen && (
          <SettingsMenu
            anchorEl={settingsMenuAnchorElement}
            closeMenu={() => {
              toggleIsSettingsMenuOpen(false);
              setSettingsMenuAnchorElement(null);
            }}
          />
        )}
      </div>
      <div>
        <button
          className={`uib-iconbutton uib-iconbutton--menu-bar settings-button ${
            isUserMenuOpen ? 'settings-button-active' : null
          }`}
          onClick={() => {
            toggleIsSettingsMenuOpen(false);
            toggleIsUserMenuOpen(!isUserMenuOpen);
          }}
        >
          <Icon iconName={'user'} />
        </button>
        {isUserMenuOpen && <UserMenu closeMenu={() => toggleIsSettingsMenuOpen(false)} />}
      </div>
    </nav>
  );
}

export { Header };
