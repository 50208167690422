import { useOktaAuth } from '@okta/okta-react';
import { useCallback} from 'react';

import { client } from '../../shared/api-client';
import { useConfigContext } from '../../shared/configContext';
import { useRRSystemContext } from '../rrSystemContext';


export function useClient<T>() {
  const { config: appconfig } = useConfigContext();
  const { rrSystemId} = useRRSystemContext();
  const {authState} = useOktaAuth();
  return useCallback((endpoint, config?) => {
    if (!authState || !authState.isAuthenticated) {
      throw new Error('Auth state is not ready');
    }
    const headers = {
      ...config?.headers,
      Authorization: `Bearer ${authState.accessToken?.accessToken}`,
      'Auth-Subject-FirstName': encodeURIComponent(authState.idToken?.claims?.firstname.toString() ?? ""),
      'Auth-Subject-LastName': encodeURIComponent(authState.idToken?.claims?.lastname.toString() ?? ""),
    };
    return client<T>(endpoint, {
      ...config,
      headers,
      apiUrl: `${appconfig?.REACT_APP_BACKOFFICE_API_URL}`,
      rrSystemId,
    });
  }, [authState,appconfig,rrSystemId]);
}
