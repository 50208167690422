import { ReactNode } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { PageHeader } from '../common/PageHeader';

export interface QuantityRestrictionsPageHeaderProps {
  children?: ReactNode;
}

function QuantityRestrictionsPageHeader({ children }: QuantityRestrictionsPageHeaderProps) {
  const intl = useIntl();
  return (
    <PageHeader breadCrumbs={QuantityRestrictionsPageBreadCrumbs(intl)}>{children}</PageHeader>
  );
}
function QuantityRestrictionsPageBreadCrumbs(intl: IntlShape) {
  return [
    {
      label: intl.formatMessage({ id: 'systems.self' }),
      route: '/systems',
    },
    {
      label: intl.formatMessage({ id: 'quantity-restrictions.self' }),
      route: '/quantity-restrictions',
    },
  ];
}
export { QuantityRestrictionsPageHeader };
