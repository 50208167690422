import { createContext, Dispatch, ReactNode, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { OptionItem, RrSystemStatus, Station } from '../types/types';

interface RRSystemContextApi {
  rrSystemId: string | null;
  selectRRSystem: (rrSystemId?: string) => void;
  exitRRSystem: () => void;
  setClickedStation: Dispatch<any>;
  clickedStation?: Station;
  rrSystemStatus: OptionItem[];
}

const RRSystemContext = createContext<RRSystemContextApi | undefined>(undefined);

interface RRSystemProviderProps {
  children: ReactNode;
}

function useQueryParamRRSystem(): string | null {
  const rrSystemKey = 'system';
    const urlParams = new URLSearchParams(window.location.search);
    const rrSystemString = urlParams.get(rrSystemKey);
    return rrSystemString ? rrSystemString : null;
}
function useSessionStorageRRSystem(): [string | null, Dispatch<string | null>] {
  const rrSystemKey = 'RR_SYSTEM';
  const rrSystemFromQuery = useQueryParamRRSystem();
  const [rrSystemId, setRRSystemId] = useState<string | null>(() => {
    const rrSystemString = sessionStorage.getItem(rrSystemKey);
    return rrSystemFromQuery ?? rrSystemString ;
  });

  useEffect(() => {
    sessionStorage.setItem(rrSystemKey, rrSystemFromQuery ?? rrSystemId ?? '');
  }, [rrSystemId, rrSystemFromQuery]);

  return [rrSystemId, setRRSystemId];
}

function RRSystemProvider({ children }: RRSystemProviderProps) {
  const intl = useIntl();
  const [rrSystemId, setRRSystem] = useSessionStorageRRSystem();
  const [clickedStation, setClickedStation] = useState<Station | undefined>();

  const rrSystemStatus = [
    {
      value: RrSystemStatus.LIVE,
      label: intl.formatMessage({
        id: 'rental-robot-systems.live',
      }),
    },
    {
      value: RrSystemStatus.NOT_LIVE,
      label: intl.formatMessage({
        id: 'rental-robot-systems.notlive',
      }),
    },
    {
      value: RrSystemStatus.UNDEFINED,
      label: intl.formatMessage({
        id: 'rental-robot-systems.undefined',
      }),
    },
  ];

  const value: RRSystemContextApi = {
    rrSystemId,
    clickedStation,
    setClickedStation,
    selectRRSystem: selectedRRSystem => {
      if (!selectedRRSystem) {
        console.error('There was an error while trying to select the RR System.');
      } else {
        if (window.location.href.includes('stations/')) {
          setClickedStation(undefined);
          history.back();
        }
        setRRSystem(selectedRRSystem);
      }
    },
    exitRRSystem: () => {
      setRRSystem(null);
    },
    rrSystemStatus,
  };

  return <RRSystemContext.Provider value={value}>{children}</RRSystemContext.Provider>;
}

function useRRSystemContext() {
  const context = useContext(RRSystemContext);
  if (context === undefined) {
    throw new Error('useRRSystemContext must be used within an RRSystemProvider');
  }
  return context;
}

export { RRSystemProvider, useRRSystemContext };
