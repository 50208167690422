import { ReactNode } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { PageHeader } from '../common/PageHeader';

export interface RentersPageHeaderProps {
  children?: ReactNode;
}

function RentersPageHeader({ children }: RentersPageHeaderProps) {
  const intl = useIntl();
  return <PageHeader breadCrumbs={rentersPageBreadCrumbs(intl)}>{children}</PageHeader>;
}
function rentersPageBreadCrumbs(intl: IntlShape) {
  return [
    {
      label: intl.formatMessage({ id: 'systems.self' }),
      route: '/systems',
    },
    {
      label: intl.formatMessage({ id: 'renters.self' }),
      route: '/renters',
    },
  ];
}
export { RentersPageHeader };
