import { ReactNode } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { PageHeader } from '../common/PageHeader';

export interface AlertRulesPageHeaderProps {
  children?: ReactNode;
}

function AlertRulesPageHeader({ children }: AlertRulesPageHeaderProps) {
  const intl = useIntl();
  return <PageHeader breadCrumbs={alertRulesPageBreadCrumbs(intl)}>{children}</PageHeader>;
}
function alertRulesPageBreadCrumbs(intl: IntlShape) {
  return [
    {
      label: intl.formatMessage({ id: 'systems.self' }),
      route: '/systems',
    },
    {
      label: intl.formatMessage({ id: 'alert-rules.self' }),
      route: '/alert-rules',
    },
  ];
}
export { AlertRulesPageHeader };
