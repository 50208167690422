import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  useUsersBySystem,
  useDeleteUserFromSystem,
  useCreateUserInSystem,
} from '../../api/hooks/useUserApi';
import { RequestErrorResponse } from '../../components/modal/ErrorModal';
import { TablePageLayout } from '../../components/table/TablePageLayout';
import { useRRSystemContext } from '../../context/rrSystemContext';
import { User } from '../../types/types';

import { CreateUserFormData } from './create-user/CreateUserForm';
import { CreateUserModal } from './create-user/CreateUserModal';
import { UsersTable } from './UsersTable';

function UsersBySystemPage() {
  const intl = useIntl();

  const [isCreatingUser, toggleIsCreatingUser] = useState<boolean>(false);

  const [selectedUserForDeletion, setSelectedUserForDeletion] = useState<User | null>();

  const [deleteUserError, setDeleteUserError] = useState<RequestErrorResponse | string | null>();

  const {
    isLoading,
    isError: isGetUsersError,
    error: getUsersError,
    users,
    remove,
  } = useUsersBySystem();
  const { rrSystemId} = useRRSystemContext();
  const {
    mutateAsync: deleteUser,
    isError: isDeleteUserError,
    error: deleteUserErrorResponse,
  } = useDeleteUserFromSystem(selectedUserForDeletion?._id ?? '', rrSystemId ?? "");
  const {
    mutateAsync: createUser,
    error: createUserError,
    isError: isCreateUserError,
    reset: resetCreateUser,
  } = useCreateUserInSystem();
  const onSubmitCreateUser = async (data: CreateUserFormData) => {
    createUser(data).finally(() => {
      toggleIsCreatingUser(false);
    });
  };
  const onConfirmDeleteUser = () => {
    deleteUser().finally(() => {
      setSelectedUserForDeletion(null);
    });
  };

  const cleanAllErrors = () => {
    resetCreateUser();
    setDeleteUserError(null);
  };

  const checkUserDeleteError = (deleteError: RequestErrorResponse) => {
    const deleteErrorStatus = deleteError.response.status;

    if (deleteErrorStatus === 403) {
      setDeleteUserError(intl.formatMessage({ id: 'user.delete.userCannotDeleteThemselves' }));
      return;
    }
    if (deleteErrorStatus === 409) {
      setDeleteUserError(intl.formatMessage({ id: 'user.delete.userCannotDeleteSuperAdmin' }));
      return;
    }
    setDeleteUserError(deleteError);
  };

  useEffect(() => {
    if (isDeleteUserError) {
      checkUserDeleteError(deleteUserErrorResponse as RequestErrorResponse);
    }
  }, [isDeleteUserError]);

  const confirmDeleteWithNames = (
    <p>
      {intl.formatMessage(
        { id: 'users.delete.confirmation' },
        {
          userFirstName: selectedUserForDeletion?.firstName,
          userLastName: selectedUserForDeletion?.lastName,
        }
      )}
    </p>
  );

  return (
    <TablePageLayout
      isContentLoading={isLoading}
      isContentError={isGetUsersError}
      isDeleteModalError={!!deleteUserError}
      deleteModalError={deleteUserError}
      contentError={getUsersError}
      permissionsErrorCode={403}
      pageName={intl.formatMessage({ id: 'users.self' })}
      pageNameSingular={intl.formatMessage({ id: 'users.singular' })}
      table={
        <UsersTable
          users={users}
          isUsersBySystemTable
          onSelectedUserForDeletion={setSelectedUserForDeletion}
        />
      }
      hasDraftModal={true}
      openCreateModal={() => toggleIsCreatingUser(true)}
      isDraftModalOpen={isCreatingUser}
      draftModal={
        <CreateUserModal
          isUserBySystemModal
          onClose={() => toggleIsCreatingUser(false)}
          onSubmit={onSubmitCreateUser}
        />
      }
      isCreateModalError={isCreateUserError}
      createModalError={createUserError}
      cleanup={remove}
      cleanupErrors={cleanAllErrors}
      isDeleteModalOpen={!!selectedUserForDeletion}
      onConfirmDelete={onConfirmDeleteUser}
      onCloseDelete={setSelectedUserForDeletion}
      confirmDeleteMessage={confirmDeleteWithNames}
    />
  );
}

export { UsersBySystemPage };
