import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useUsers, useCreateUser, useDeleteUser, usePatchUser } from '../../api/hooks/useUserApi';
import { RequestErrorResponse } from '../../components/modal/ErrorModal';
import { TablePageLayout } from '../../components/table/TablePageLayout';
import {
  CreateUserDialog,
  CreateUserFormData,
} from '../../components/UserManagement/CreateUserDialog';
import { DeleteUserDialog } from '../../components/UserManagement/DeleteUserDialog';
import { DetailUserDialog } from '../../components/UserManagement/DetailUserDialog';
import { PatchUserDialog } from '../../components/UserManagement/PatchUserDialog';
import { useRRSystemContext } from '../../context/rrSystemContext';
import { EditRequest, User } from '../../types/types';

import { UsersTable } from './UsersTable';

function UserManagementPage() {
  const { rrSystemId: rrSystem, exitRRSystem } = useRRSystemContext();
  if (rrSystem) {
    exitRRSystem();
  }
  const intl = useIntl();
  const [isCreatingUser, toggleIsCreatingUser] = useState<boolean>(false);
  const [selectedUserForDetailView, setSelectedUserForDetailView] = useState<User | null>();

  const [selectedUserForPatch, setSelectedUserForPatch] = useState<User | null>();

  const [selectedUserForDeletion, setSelectedUserForDeletion] = useState<User | null>();
  const [deleteUserError, setDeleteUserError] = useState<RequestErrorResponse | string | null>();

  const { isLoading, isError: isGetUsersError, error: getUsersError, users, remove } = useUsers();
  const {
    mutateAsync: createUser,
    isError: isCreateUserError,
    error: createUserError,
    reset: resetCreateUser,
  } = useCreateUser();
  const { mutateAsync: patchUser, reset: resetPatchUser } = usePatchUser();
  const {
    mutateAsync: deleteUser,
    isError: isDeleteUserError,
    error: deleteUserErrorResponse,
  } = useDeleteUser(selectedUserForDeletion?._id ?? '');

  const onSubmitCreateUser = async (data: CreateUserFormData) => {
    createUser(data).finally(() => {
      toggleIsCreatingUser(false);
    });
  };

  const onSubmitPatchUser = async (data: EditRequest) => {
    patchUser(data).finally(() => {
      setSelectedUserForPatch(null);
    });
  };

  const onConfirmDeleteUser = () => {
    deleteUser().finally(() => {
      setSelectedUserForDeletion(null);
    });
  };

  const cleanAllErrors = () => {
    resetCreateUser();
    resetPatchUser();
    setDeleteUserError(null);
  };

  const checkUserDeleteError = (deleteError: RequestErrorResponse) => {
    const deleteErrorStatus = deleteError.response.status;

    if (deleteErrorStatus === 403) {
      setDeleteUserError(intl.formatMessage({ id: 'user.delete.userCannotDeleteThemselves' }));
      return;
    }
    if (deleteErrorStatus === 409) {
      setDeleteUserError(intl.formatMessage({ id: 'user.delete.userCannotDeleteSuperAdmin' }));
      return;
    }
    setDeleteUserError(deleteError);
  };

  useEffect(() => {
    if (isDeleteUserError) {
      checkUserDeleteError(deleteUserErrorResponse as RequestErrorResponse);
    }
  }, [isDeleteUserError]);

  return (
    <>
      <DeleteUserDialog
        setSelectedUserForDeletion={setSelectedUserForDeletion}
        selectedUserForDeletion={selectedUserForDeletion}
        onConfirmDeleteUser={onConfirmDeleteUser}
      />
      <CreateUserDialog
        isCreatingUser={isCreatingUser}
        toggleIsCreatingUser={toggleIsCreatingUser}
        onSubmit={onSubmitCreateUser}
      />
      {selectedUserForDetailView && (
        <DetailUserDialog
          selectedUserForDetailView={selectedUserForDetailView}
          setSelectedUserForDetailView={setSelectedUserForDetailView}
          userId={selectedUserForDetailView._id}
        />
      )}
      {selectedUserForPatch && (
        <PatchUserDialog
          setSelectedUserForPatch={setSelectedUserForPatch}
          selectedUserForPatch={selectedUserForPatch}
          onSubmit={onSubmitPatchUser}
        />
      )}
      <TablePageLayout
        isContentLoading={isLoading}
        isContentError={isGetUsersError}
        isDeleteModalError={!!deleteUserError}
        deleteModalError={deleteUserError}
        contentError={getUsersError}
        permissionsErrorCode={403}
        pageName={intl.formatMessage({ id: 'users.management' })}
        pageNameSingular={intl.formatMessage({ id: 'users.singular' })}
        table={
          <UsersTable
            users={users}
            isUsersBySystemTable={false}
            onSelectUserForDetailView={setSelectedUserForDetailView}
            onSelectUserForPatchView={setSelectedUserForPatch}
            onSelectedUserForDeletion={setSelectedUserForDeletion}
          />
        }
        hasDraftModal={true}
        openCreateModal={() => toggleIsCreatingUser(true)}
        isDraftModalOpen={isCreatingUser}
        isCreateModalError={isCreateUserError}
        createModalError={createUserError}
        cleanup={remove}
        cleanupErrors={cleanAllErrors}
      />
    </>
  );
}

export { UserManagementPage };
