import { IntlShape, useIntl } from 'react-intl';

import { BreadcrumbItem, PageHeader } from '../../../components/common/PageHeader';

export function ReportsPageHeader() {
  const intl = useIntl();
  return <PageHeader breadCrumbs={reportsPageBreadCrumbs(intl)} />;
}

function reportsPageBreadCrumbs(intl: IntlShape): BreadcrumbItem[] {
  return [
    {
      label: intl.formatMessage({ id: 'systems.self' }),
      route: '/systems',
    },
    {
      label: intl.formatMessage({ id: 'reports.self' }),
      route: '/reports',
    },
  ];
}
