import { Tab, Tabs } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';

import { useSystems } from '../../api/hooks/useSystemsApi';
import { BreadcrumbItem, PageHeader } from '../../components/common/PageHeader';
import { useRRSystemContext } from '../../context/rrSystemContext';

import { AlertRulesApiDocsTab } from './AlertRulesApiDocsTab';
import { GenerateApiKeyButton } from './GenerateApiKeyButton';
import { RentingApiDocsTab } from './RentingApiDocsTab';
import { SystemUsersApiDocsTab } from './SystemUsersApiDocsTab';

export function ApiDocsPage() {
  const [currentTabIndex, setTabIndex] = useState(0);
  const intl = useIntl();
  const { systems, isLoading } = useSystems();
  const { selectRRSystem, rrSystemId: rrSystem } = useRRSystemContext();

  useEffect(() => {
    if (systems.length > 0 && !rrSystem) selectRRSystem(systems[0]._id);
  }, [isLoading]);

  const changeTab = (event: React.SyntheticEvent, index: number) => {
    setTabIndex(index);
  };
  return (
    <>
      <PageHeader breadCrumbs={breadCrumbs(intl)} />
      {rrSystem && (
        <>
          <GenerateApiKeyButton />
          <Tabs value={currentTabIndex} onChange={changeTab}>
            <Tab label={intl.formatMessage({ id: 'renters.verb' })} />
            <Tab label={intl.formatMessage({ id: 'alert-rules.self' })} />
            <Tab label={intl.formatMessage({ id: 'users.self' })} />
          </Tabs>
          <TabPanel currentTabIndex={currentTabIndex} index={0}>
            <RentingApiDocsTab />
          </TabPanel>
          <TabPanel currentTabIndex={currentTabIndex} index={1}>
            <AlertRulesApiDocsTab />
          </TabPanel>
          <TabPanel currentTabIndex={currentTabIndex} index={2}>
            <SystemUsersApiDocsTab />
          </TabPanel>
        </>
      )}
    </>
  );
}
interface TabPanelProps {
  children: ReactNode;
  currentTabIndex: number;
  index: number;
}
function TabPanel({ currentTabIndex, index, children }: TabPanelProps) {
  return <div hidden={currentTabIndex != index}>{children}</div>;
}

function breadCrumbs(intl: IntlShape): BreadcrumbItem[] {
  return [
    {
      label: intl.formatMessage({ id: 'systems.self' }),
      route: '#',
    },
    {
      label: intl.formatMessage({ id: 'api-docs.self' }),
      route: '/developer',
    },
  ];
}
