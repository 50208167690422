import { LoginCallback } from '@okta/okta-react';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';

import { useRRSystemContext } from '../../context/rrSystemContext';
import { useMenuItems } from '../../hooks/useMenuItems';
import { RentalHistory } from '../../modules/reports/RentalHistory';
import { ServiceHistory } from '../../modules/reports/ServiceHistory';
import { StationsPage } from '../../modules/stations/StationsPage';
import { UserManagementPage } from '../../modules/users/UserManagementPage';
import { ApiDocsPage } from '../../views/api-docs/ApiDocsPage';
import { ServiceTechnicianDashboard } from '../../views/dashboard/ServiceTechnicianDashboard';
import { CreateItemSetTemplatePage } from '../../views/item-set/CreateItemSetTemplatePage';
import { ShopsPage } from '../../views/shops/ShopsPage';
import { SystemsPage } from '../../views/systems/SystemsPage';

import { AuthChecker } from './AuthChecker';
import { MenuItem } from './BurgerMenu';

const globalEndpoints: string[] = ['/login/callback','/','/systems', '/user-mgmt', '/developer'];
function AppRoutes() {
  const { menuItems } = useMenuItems();
  const {rrSystemId: rrSystem} = useRRSystemContext();

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    // if rrSystem is not set we need to redirect to the main page except when in a global page
    if (!rrSystem && !globalEndpoints.some(e => e == location.pathname)) {
        navigate('/systems');
    }
  }, [location.pathname]);
  
  return (
    <Routes>
      <Route path='/login/callback' element={<LoginCallback />}/>
      <Route path='/' element={<AuthChecker/>}>
        <Route path="/" element={<SystemsPage />} />
        <Route path="/systems" element={<SystemsPage />} />
        <Route path="/user-mgmt" element={<UserManagementPage />} />
        <Route path="/developer" element={<ApiDocsPage />} />
        {getRoutesFromMenu(menuItems)}
        <Route path="/shops" element={<ShopsPage />} />
        <Route path="/item-set-templates/create" element={<CreateItemSetTemplatePage />} />
        <Route path="/stations/:stationId/:cabinetId" element={<StationsPage />} />
        <Route path="/stations/:stationId" element={<StationsPage />} />
        <Route path="/reports/rental-history" element={<RentalHistory />} />
        <Route path="/reports/service-history" element={<ServiceHistory />} />
        <Route path="/dashboard" element={<ServiceTechnicianDashboard />} />
        <Route path="/*" element={<Navigate to="/systems" />} />
      </Route>
    </Routes>
  );

  function getRoutesFromMenu(menuItems: MenuItem[]) {
    return menuItems.map(menuItem => {
      return (
        <Route
          path={menuItem.to}
          element={menuItem.component ? <menuItem.component /> : undefined}
          key={menuItem.to}
        />
      );
    });
  }
}

export { AppRoutes };
